import { last } from "lodash";

import { isErrorlessAst } from "./calcAstUtils.js";
import {
  CalcLexer,
  UnrecognizedTokenError,
  isKnownLexerError,
} from "./CalcLexer.js";
import { Node } from "./calcTypes.js";
import {
  CalcToken,
  TokenType,
  getInfixPrecedence,
  getPrefixPrecedence,
  isBinaryOperator,
} from "./Token.js";

// integers outside these values don't deserialized properly to an int in Java
// see https://hex-tech-hq.slack.com/archives/C06570ALZ7F/p1710258656806759
const MIN_INT = -(2 ** 31);
const MAX_INT = 2 ** 31 - 1;

export type PositionInfo = {
  /** Character where the node (and all its children) start */
  start: number;
  /** Character where the node (and all its children) end */
  end: number;
  /** Character where the node's own content (excluding children) starts */
  selfStart: number;
  /** Character where the node's own content (excluding children) ends */
  selfEnd: number;
};

// Fancy TypeScript recursive type to add position info to all nodes in an AST type
type WithPositionInfo<T extends object> = {
  [K in keyof T]: T[K] extends object[]
    ? WithPositionInfo<T[K][number]>[]
    : T[K] extends object
      ? WithPositionInfo<T[K]>
      : T[K];
} & PositionInfo;

type ParentInfo = {
  parent?: CalcParseNode | undefined;
};

type WithParentInfo<T extends object> = {
  [K in keyof T]: T[K] extends object[]
    ? WithParentInfo<T[K][number]>[]
    : T[K] extends object
      ? WithParentInfo<T[K]>
      : T[K];
} & ParentInfo;

type CalcParseErrorTypes =
  | "unclosed-function"
  | "unclosed-paren"
  | "unclosed-double-braces";
export type CalcParseError = WithParentInfo<
  WithPositionInfo<{
    type: "error";
    errorType?: CalcParseErrorTypes;
    message: string;
  }>
>;

type AdditionalErrorInfo = {
  additionalErrors?: CalcParseError[];
};

// Fancy TypeScript recursive type that essentially appends `| CalcParseError`
// to the type of any non-scalar field in the type (including nested fields).
type OrError<T extends object> = {
  [K in keyof T]: T[K] extends object[]
    ? Array<OrError<T[K][number] | CalcParseError>>
    : T[K] extends object
      ? OrError<T[K]> | CalcParseError
      : T[K];
} & AdditionalErrorInfo;

/** A node where it and all of its descendants are error-free */
export type CalcParseNodeWithoutErrors = WithParentInfo<WithPositionInfo<Node>>;
/** A non-error node where any descendant might be an error */
export type CalcParseNode = WithParentInfo<WithPositionInfo<OrError<Node>>>;
/** A node which may or may not be an error and where any descendant might be an error */
export type CalcParseNodeOrError = CalcParseNode | CalcParseError;

export type CalcParseResult = CalcParseNodeOrError;

// mini helper function to add position info based on a single token
function positions(token: CalcToken): PositionInfo {
  return {
    start: token.start,
    end: token.end,
    selfStart: token.start,
    selfEnd: token.end,
  };
}

export class CalcParser {
  private lexer: CalcLexer;

  constructor(lexer: CalcLexer) {
    this.lexer = lexer;
  }

  public parse(): CalcParseResult {
    const node = this.parseExpression(0);

    // we only expect the last token to be EOF if there were no parsing errors along the way.
    if (
      isErrorlessAst(node) &&
      this.lexer.getCurrentToken().type !== TokenType.EOF
    ) {
      const token = this.lexer.getCurrentToken();

      node.additionalErrors ??= [];
      node.additionalErrors.push({
        type: "error",
        message: "Unexpected starting token: " + token.type,
        ...positions(token),
      });
    }
    return node;
  }

  private consumeTokenOrError(): undefined | CalcParseError {
    try {
      this.lexer.consumeToken();
      return undefined;
    } catch (err: unknown) {
      if (!(err instanceof UnrecognizedTokenError)) throw err;
      return {
        type: "error",
        message: err.message,
        start: err.start,
        end: err.end,
        selfStart: err.start,
        selfEnd: err.end,
      };
    }
  }

  private parseExpression(minPrecedence: number): CalcParseNodeOrError {
    const { lexer } = this;
    let leftExpr = this.parsePrimary();

    let opToken = lexer.getCurrentToken();
    while (
      leftExpr.type !== "error" &&
      opToken.type !== TokenType.EOF &&
      opToken.type !== TokenType.RPAREN &&
      opToken.type !== TokenType.COMMA &&
      getInfixPrecedence(opToken.type) >= minPrecedence &&
      isBinaryOperator(opToken.type)
    ) {
      const opType = opToken.type;

      const maybeErrNode = this.consumeTokenOrError();
      const rightExpr =
        maybeErrNode == null
          ? this.parseExpression(getInfixPrecedence(opType) + 1)
          : maybeErrNode;

      leftExpr = {
        type: "binaryOp",
        op: opType,
        left: leftExpr,
        right: rightExpr,
        start: leftExpr.start,
        end: rightExpr.end,
        selfStart: opToken.start,
        selfEnd: opToken.end,
      };
      leftExpr.left.parent = leftExpr;
      leftExpr.right.parent = leftExpr;

      if (rightExpr.type === "error") {
        break;
      }

      opToken = lexer.getCurrentToken();
    }

    return leftExpr;
  }

  private parsePrimary(): CalcParseNodeOrError {
    const { lexer } = this;
    const token = lexer.getCurrentToken();
    const maybeErrNode = this.consumeTokenOrError();
    if (maybeErrNode != null) {
      return maybeErrNode;
    }

    switch (token.type) {
      case TokenType.NUMBER: {
        if (
          token.value.includes(".") ||
          token.value.toLowerCase().includes("e")
        ) {
          return {
            type: "float",
            value: parseFloat(token.value),
            ...positions(token),
          };
        } else {
          const intValue = parseInt(token.value, 10);

          // for integers outside of a standard 4-byte integer type,
          // we convert to a float instead (which could re)
          if (intValue < MIN_INT || intValue > MAX_INT) {
            return {
              type: "float",
              value: parseFloat(token.value),
              ...positions(token),
            };
          }

          return {
            type: "integer",
            value: intValue,
            ...positions(token),
          };
        }
      }
      case TokenType.LPAREN: {
        const expr = this.parseExpression(0);
        try {
          lexer.expectToken(TokenType.RPAREN);
        } catch (err: unknown) {
          if (!isKnownLexerError(err)) throw err;

          // we already have an error, so just return it and don't worry about the additional error
          if (expr.type === "error") return expr;

          expr.additionalErrors = [
            {
              type: "error",
              errorType: "unclosed-paren",
              message: "Missing closing ')' after opening '('",
              start: token.start,
              end: lexer.getCurrentToken().end,
              selfStart: lexer.getCurrentToken().start,
              selfEnd: lexer.getCurrentToken().end,
            },
          ];
        }
        return expr;
      }
      case TokenType.LDOUBLEBRACE: {
        const ident = lexer.getCurrentToken();
        try {
          lexer.expectToken(TokenType.IDENTIFIER);
        } catch (err: unknown) {
          if (!isKnownLexerError(err)) throw err;

          return {
            type: "error",
            message: "Expected a variable name after '{{'",
            ...positions(ident),
          };
        }

        const rightBraces = lexer.getCurrentToken();
        try {
          lexer.expectToken(TokenType.RDOUBLEBRACE);
        } catch (err: unknown) {
          if (!isKnownLexerError(err)) throw err;

          return {
            type: "error",
            errorType: "unclosed-double-braces",
            message: "Missing closing '}}' after variable name",
            start: token.start,
            end: ident.end,
            selfStart: token.start,
            selfEnd: ident.end,
          };
        }

        return {
          type: "parameterReference",
          name: ident.value,
          start: token.start,
          end: rightBraces.end,
          selfStart: token.start,
          selfEnd: rightBraces.end,
        };
      }
      case TokenType.MINUS:
      case TokenType.LOGICALNOT: {
        const operand = this.parseExpression(getPrefixPrecedence(token.type));
        const node: CalcParseNode = {
          type: "unaryOp",
          op: token.type,
          left: operand,
          start: token.start,
          end: operand.end,
          selfStart: token.start,
          selfEnd: token.end,
        };
        node.left.parent = node;
        return node;
      }
      case TokenType.IDENTIFIER: {
        const lowerVal = token.value.toLowerCase();

        if (lexer.getCurrentToken().type === TokenType.LPAREN) {
          let lastArgEnd = lexer.getCurrentToken().end;
          lexer.expectToken(TokenType.LPAREN); // don't need to catch an error here because we verify the token type above

          const args: CalcParseNodeOrError[] = [];
          while (
            lexer.getCurrentToken().type !== TokenType.RPAREN &&
            lexer.getCurrentToken().type !== TokenType.EOF
          ) {
            const arg = this.parseExpression(0);
            arg.start = lastArgEnd;
            args.push(arg);

            if (arg.type === "error") {
              break;
            }

            if (lexer.getCurrentToken().type === TokenType.COMMA) {
              // We extend the length of any argument to go up to the comma separating it from the next arg
              arg.end = lexer.getCurrentToken().end;
              lastArgEnd = arg.end;
              lexer.expectToken(TokenType.COMMA); // don't need to catch an error here because we verify the token type above
            }
          }

          const funcEnd = lexer.getCurrentToken().end;
          const funcNode: CalcParseNodeOrError = {
            type: "function",
            name: token.value,
            args,
            start: token.start,
            end: funcEnd,
            selfStart: token.start,
            selfEnd: token.end,
          };
          for (const arg of funcNode.args) {
            arg.parent = funcNode;
          }

          try {
            const parenToken = lexer.getCurrentToken();
            lexer.expectToken(TokenType.RPAREN);

            const lastArg = last(funcNode.args);
            if (lastArg != null) lastArg.end = parenToken.end - 1;
          } catch (err: unknown) {
            if (!isKnownLexerError(err)) throw err;
            funcNode.additionalErrors = [
              {
                type: "error",
                errorType: "unclosed-function",
                message: "Missing closing ')' for function call",
                start: token.start,
                end: funcEnd,
                selfStart: lexer.getCurrentToken().start,
                selfEnd: lexer.getCurrentToken().end,
              },
            ];
          }

          return funcNode;
        } else if (lowerVal === "true") {
          return { type: "boolean", value: true, ...positions(token) };
        } else if (lowerVal === "false") {
          return { type: "boolean", value: false, ...positions(token) };
        } else if (lowerVal === "null") {
          return { type: "null", ...positions(token) };
        } else if (lowerVal === "nan") {
          return { type: "float", value: Number.NaN, ...positions(token) };
        } else if (lowerVal === "inf" || lowerVal === "infinity") {
          return {
            type: "float",
            value: Number.POSITIVE_INFINITY,
            ...positions(token),
          };
        } else {
          return { type: "column", name: token.value, ...positions(token) };
        }
      }
      case TokenType.BACKTICKCOLUMN: {
        // Removing leading/trailing backticks and unescaping internal backticks
        const quotedColumn = token.value;
        const column = quotedColumn
          .substring(1, quotedColumn.length - 1)
          .replace("\\`", "`");
        return { type: "column", name: column, ...positions(token) };
      }
      case TokenType.DOUBLEQUOTESTRING: {
        const quotedValue = token.value;
        const value = quotedValue
          .substring(1, quotedValue.length - 1)
          .replace('\\"', '"');
        return { type: "str", value, ...positions(token) };
      }
      case TokenType.SINGLEQUOTESTRING: {
        const quotedValue = token.value;
        const value = quotedValue
          .substring(1, quotedValue.length - 1)
          .replace("\\'", "'");
        return { type: "str", value, ...positions(token) };
      }
      default: {
        return {
          type: "error",
          message: "Unexpected token: " + token.type,
          ...positions(token),
        };
      }
    }
  }
}

// convenience function for parsing an expression all in one easy function call
export function parseCalcExpression(expression: string): CalcParseResult {
  const lexer = new CalcLexer(expression);
  const parser = new CalcParser(lexer);
  return parser.parse();
}

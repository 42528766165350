import { Literal, Record, Static, Union } from "runtypes";

import { CellId } from "../idTypeBrands";
import { SqlCellActiveTabLiteral } from "../sql";

export const SQLCellBlockConfig = Record({
  // This should include a field type Literal("SQL_CELL") which is nice because
  // we can narrow typing based on that, vs using guard because then we can add
  // new fields without old configs breaking.
  sqlCellId: CellId,
  chartCellId: CellId,
  activeTab: SqlCellActiveTabLiteral,
});
export type SQLCellBlockConfig = Static<typeof SQLCellBlockConfig>;

export const AirlockBlockConfig = Record({
  type: Literal("AIRLOCK"),
});
export type AirlockBlockConfig = Static<typeof AirlockBlockConfig>;

export const BlockConfig = Union(SQLCellBlockConfig, AirlockBlockConfig);
export type BlockConfig = Static<typeof BlockConfig>;

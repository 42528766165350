import { BinaryOperator, UnaryOperator } from "./calcTypes.js";

export const TokenType = {
  NUMBER: "NUMBER",
  PLUS: "PLUS",
  MINUS: "MINUS",
  MULTIPLY: "MULTIPLY",
  DIVIDE: "DIVIDE",
  EXPONENT: "EXPONENT",
  MODULO: "MODULO",
  EQUAL: "EQUAL",
  NOTEQUAL: "NOTEQUAL",
  GREATER: "GREATER",
  GREATEREQUAL: "GREATEREQUAL",
  LESS: "LESS",
  LESSEQUAL: "LESSEQUAL",
  LOGICALOR: "LOGICALOR",
  LOGICALAND: "LOGICALAND",
  LOGICALNOT: "LOGICALNOT",
  CONCAT: "CONCAT",
  IDENTIFIER: "IDENTIFIER",
  LPAREN: "LPAREN",
  RPAREN: "RPAREN",
  LDOUBLEBRACE: "LDOUBLEBRACE",
  RDOUBLEBRACE: "RDOUBLEBRACE",
  COMMA: "COMMA",
  BACKTICKCOLUMN: "BACKTICKCOLUMN",
  DOUBLEQUOTESTRING: "DOUBLEQUOTESTRING",
  SINGLEQUOTESTRING: "SINGLEQUOTESTRING",
  UNCLOSEDBACKTICKCOLUMN: "UNCLOSEDBACKTICKCOLUMN",
  UNCLOSEDDOUBLEQUOTESTRING: "UNCLOSEDDOUBLEQUOTESTRING",
  UNCLOSEDSINGLEQUOTESTRING: "UNCLOSEDSINGLEQUOTESTRING",
  EOF: "EOF", // End of file/input
} as const;
export type TokenType = (typeof TokenType)[keyof typeof TokenType];

export interface CalcToken {
  readonly type: TokenType;
  readonly value: string;
  readonly start: number;
  readonly end: number;
}

export function getInfixPrecedence(tokenType: TokenType): number {
  switch (tokenType) {
    case TokenType.CONCAT:
      return 0;
    case TokenType.LOGICALOR:
      return 1;
    case TokenType.LOGICALAND:
      return 2;
    case TokenType.GREATER:
    case TokenType.GREATEREQUAL:
    case TokenType.LESS:
    case TokenType.LESSEQUAL:
    case TokenType.EQUAL:
    case TokenType.NOTEQUAL:
      return 3;
    case TokenType.PLUS:
    case TokenType.MINUS:
      return 4;
    case TokenType.MULTIPLY:
    case TokenType.DIVIDE:
    case TokenType.MODULO:
      return 5;
    case TokenType.EXPONENT:
      return 6;
    default:
      // If we forget to add a binary operator to the list above, this will generate a type error
      // See https://stackoverflow.com/a/76427563/3124288
      type _ErrorIfOverlapping<
        _ extends never = typeof tokenType & BinaryOperator,
      > = void;

      return -1; // Lowest precedence for other operators or default cases
  }
}

export function getPrefixPrecedence(tokenType: TokenType): number {
  switch (tokenType) {
    case TokenType.MINUS:
    case TokenType.LOGICALNOT:
      return 7;
    default:
      type _ErrorIfOverlapping<
        _ extends never = typeof tokenType & UnaryOperator,
      > = void;
      return 0;
  }
}

export function isBinaryOperator(
  tokenType: TokenType,
): tokenType is BinaryOperator {
  // special case for `AS`, which we don't treat as a normal binary operator
  return getInfixPrecedence(tokenType) > -1;
}

export function isUnaryOperator(
  tokenType: TokenType,
): tokenType is UnaryOperator {
  return getPrefixPrecedence(tokenType) > 0;
}

import { assertIsDefined } from "../assertions.js";

import { CalcToken, TokenType } from "./Token.js";

export class UnrecognizedTokenError extends Error {
  constructor(
    public text: string,
    public start: number,
    public end: number,
  ) {
    super(`Unrecognized token: ${text}`);
    this.name = "UnrecognizedTokenError";
  }
}

class UnexpectedTokenError extends Error {
  constructor(
    public expectedTokenType: TokenType,
    public foundToken: CalcToken,
  ) {
    super(
      "Expected token type " +
        expectedTokenType +
        " but found " +
        foundToken.type +
        " at position " +
        foundToken.start,
    );
    this.name = "UnexpectedToken";
  }
}

export function isKnownLexerError(
  err: unknown,
): err is UnrecognizedTokenError | UnexpectedTokenError {
  return (
    err instanceof UnrecognizedTokenError || err instanceof UnexpectedTokenError
  );
}

function getTokenRegex(): RegExp {
  return new RegExp(
    "(?<NUMBER>((\\d+(\\.\\d*)?)|(\\.\\d+))([eE][-+]?\\d+)?)|" +
      "(?<PLUS>\\+)|" +
      "(?<MINUS>-)|" +
      "(?<NOTEQUAL>!=|<>)|" +
      "(?<LOGICALNOT>!)|" +
      "(?<EXPONENT>\\^|\\*\\*)|" +
      "(?<MULTIPLY>\\*)|" +
      "(?<DIVIDE>/)|" +
      "(?<MODULO>%)|" +
      "(?<GREATEREQUAL>>=)|" +
      "(?<GREATER>>)|" +
      "(?<LESSEQUAL><=)|" +
      "(?<LESS><)|" +
      "(?<EQUAL>==|=)|" +
      "(?<LOGICALAND>&&)|" +
      "(?<CONCAT>&)|" +
      "(?<LOGICALOR>\\|\\|)|" +
      "(?<LPAREN>\\()|" +
      "(?<RPAREN>\\))|" +
      "(?<LDOUBLEBRACE>{{)|" +
      "(?<RDOUBLEBRACE>}})|" +
      "(?<COMMA>,)|" +
      "(?<BACKTICKCOLUMN>`((?:\\\\`|[^`])*)`)|" +
      '(?<DOUBLEQUOTESTRING>"((?:\\\\"|[^"])*)")|' +
      "(?<SINGLEQUOTESTRING>'((?:\\\\'|[^'])*)')|" +
      "(?<UNCLOSEDBACKTICKCOLUMN>`((?:\\\\`|[^`])*)$)|" +
      '(?<UNCLOSEDDOUBLEQUOTESTRING>"((?:\\\\"|[^"])*)$)|' +
      "(?<UNCLOSEDSINGLEQUOTESTRING>'((?:\\\\'|[^'])*)$)|" +
      "(?<IDENTIFIER>[a-zA-Z_][a-zA-Z_0-9]*)",
    "g",
  );
}

export class CalcLexer {
  public readonly input: string;

  private readonly matcher: RegExp;
  private match: RegExpExecArray | null = null;

  // this is initialized by the `advance()` call in the constructor
  private currentToken!: CalcToken;
  private currentPosition: number = 0;

  constructor(input: string) {
    this.input = input.trimEnd();
    this.matcher = getTokenRegex();
    this.advance();
  }

  private advance(): void {
    if (this.currentPosition === this.input.length) {
      this.currentToken = {
        type: TokenType.EOF,
        value: "",
        start: this.input.length,
        end: this.input.length,
      };
      return;
    }

    this.match = this.matcher.exec(this.input);
    if (this.match != null) {
      const skipped = this.input.substring(
        this.currentPosition,
        this.match.index,
      );
      if (skipped.trim() !== "") {
        // We skipped non-whitespace characters
        this.raiseUnrecognizedTokenError();
      }
      const updated =
        this.updateToken(TokenType.NUMBER) ||
        this.updateToken(TokenType.PLUS) ||
        this.updateToken(TokenType.MINUS) ||
        this.updateToken(TokenType.NOTEQUAL) ||
        this.updateToken(TokenType.LOGICALNOT) ||
        this.updateToken(TokenType.MULTIPLY) ||
        this.updateToken(TokenType.DIVIDE) ||
        this.updateToken(TokenType.EXPONENT) ||
        this.updateToken(TokenType.MODULO) ||
        this.updateToken(TokenType.GREATEREQUAL) ||
        this.updateToken(TokenType.GREATER) ||
        this.updateToken(TokenType.LESSEQUAL) ||
        this.updateToken(TokenType.LESS) ||
        this.updateToken(TokenType.EQUAL) ||
        this.updateToken(TokenType.LOGICALAND) ||
        this.updateToken(TokenType.CONCAT) ||
        this.updateToken(TokenType.LOGICALOR) ||
        this.updateToken(TokenType.LPAREN) ||
        this.updateToken(TokenType.RPAREN) ||
        this.updateToken(TokenType.LDOUBLEBRACE) ||
        this.updateToken(TokenType.RDOUBLEBRACE) ||
        this.updateToken(TokenType.COMMA) ||
        this.updateToken(TokenType.BACKTICKCOLUMN) ||
        this.updateToken(TokenType.DOUBLEQUOTESTRING) ||
        this.updateToken(TokenType.SINGLEQUOTESTRING) ||
        this.updateToken(TokenType.UNCLOSEDBACKTICKCOLUMN) ||
        this.updateToken(TokenType.UNCLOSEDDOUBLEQUOTESTRING) ||
        this.updateToken(TokenType.UNCLOSEDSINGLEQUOTESTRING) ||
        this.updateIdentifierToken();

      if (!updated) {
        this.raiseUnrecognizedTokenError();
      } else {
        this.currentPosition = this.matcher.lastIndex;
      }
    } else {
      this.raiseUnrecognizedTokenError();
    }
  }

  private updateToken(ttype: TokenType): boolean {
    assertIsDefined(this.match);

    const value = this.match.groups?.[ttype];
    if (value != null) {
      this.currentToken = {
        type: ttype,
        value,
        start: this.match.index,
        end: this.matcher.lastIndex,
      };
      return true;
    } else {
      return false;
    }
  }

  private updateIdentifierToken(): boolean {
    assertIsDefined(this.match);

    const value = this.match.groups?.["IDENTIFIER"];
    const start = this.match.index;
    const end = this.matcher.lastIndex;

    let tokenType: TokenType = TokenType.IDENTIFIER;
    if (value != null) {
      const lowerVal = value.toLowerCase();
      // Map AND/OR/NOT/AS to operator tokens
      if (lowerVal === "and") {
        tokenType = TokenType.LOGICALAND;
      } else if (lowerVal === "or") {
        tokenType = TokenType.LOGICALOR;
      } else if (lowerVal === "not") {
        tokenType = TokenType.LOGICALNOT;
      }

      this.currentToken = { type: tokenType, value, start, end };
      return true;
    } else {
      return false;
    }
  }

  private raiseUnrecognizedTokenError(): void {
    let nextNonWhitespacePosition = this.currentPosition;
    while (this.input.charAt(nextNonWhitespacePosition).trim() === "") {
      nextNonWhitespacePosition += 1;
    }
    const end = Math.min(nextNonWhitespacePosition + 10, this.input.length);
    const text = this.input.substring(nextNonWhitespacePosition, end);
    throw new UnrecognizedTokenError(text, nextNonWhitespacePosition, end);
  }

  public getCurrentToken(): CalcToken {
    return this.currentToken;
  }

  public consumeToken(): void {
    this.advance();
  }

  public expectToken(ttype: TokenType): void {
    if (this.currentToken.type === ttype) {
      this.advance();
    } else {
      throw new UnexpectedTokenError(ttype, this.currentToken);
    }
  }

  *[Symbol.iterator](): Generator<CalcToken, void> {
    // make a new lexer copy to reset state to the beginning of the string for iteration
    const copy = new CalcLexer(this.input);

    yield copy.currentToken;
    while (copy.currentToken.type !== TokenType.EOF) {
      copy.advance();
      yield copy.currentToken;
    }
  }
}
